import React from 'react';
import Layout from '../components/layout/layout';

import { PRIMARY_TEXT_COLOR } from '../constants';
import { Wrapper } from '../components/shared/wrapper';
import { Fullscreen } from '../components/fullscreen/fullscreen';

import { TitleContainer, TitleText } from '../components/shared';
import Container from 'reactstrap/es/Container';
import { TextBlock } from '../components/shared/text-block';

export default () => {
    return (
        <Layout>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <TitleContainer>
                    <TitleText>Terms and Conditions</TitleText>
                </TitleContainer>
            </Fullscreen>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Container>
                    <TextBlock title="Introduction">
                        <p>
                            This document sets out our Terms and Conditions of Business. "We", "our" or "us" means CREA d.o.o., Brnčičeva
                            39, 1231 Ljubljana - Črnuče, Slovenia.
                        </p>
                        <p>
                            They form part of the contract that we will have with you. When you accept the Contract, you are acknowledging
                            that you also accept these Terms. So, before we enter into a Contract with you, please read them through so that
                            we all know precisely where we stand. If there’s anything you don’t understand, please get in touch so that we
                            can discuss your concerns.
                        </p>
                    </TextBlock>
                    <TextBlock title="1. Definitions">
                        <p>
                            To make things a little easier, there are certain words in these Terms that we will use several times, so rather
                            than defining them each time, we will define them here.
                        </p>
                        <div className="definition">
                            <h4>Contract:</h4>
                            <p>
                                contract between you and us that is based on the Proposal, subject to these Terms, incorporating any other
                                documentation referred to in these Terms or the Proposal, such as Special Conditions or contract variations;
                            </p>
                        </div>
                        <div className="definition">
                            <h4>Completion:</h4>
                            <p>
                                the completion of the Project, which will take effect when we upload the software to the Distributor or make
                                it available to the public via a web server or in some other way or otherwise receive your sign-off in
                                accordance with clause 5.5;
                            </p>
                        </div>
                        <div className="definition">
                            <h4>Distributor:</h4>
                            <p>Apple’s AppStore, Google Play or any other portal through which software is delivered to the users;</p>
                        </div>
                        <div className="definition">
                            <h4>Embargo Date:</h4>
                            <p>
                                the date specified in the Contract, if any, before which we will make no reference to the Project or the
                                Contract;
                            </p>
                        </div>
                        <div className="definition">
                            <h4>Fees:</h4>
                            <p>
                                the price stated in the Proposal that we are to charge for the delivery of the Services, excluding VAT and
                                other applicable taxes and any expenses that must be incurred in the delivery of the Project;
                            </p>
                        </div>
                        <div className="definition">
                            <h4>Input Material:</h4>
                            <p>any materials of any description that you are to provide to us for the purposes of the Project;</p>
                        </div>
                        <div className="definition">
                            <h4>IP:</h4>
                            <p>
                                copyright, trademarks, patents, registered and unregistered design rights, confidential information and all
                                other intellectual property and proprietary rights, powers and benefits, including the right to register,
                                transfer, licence and assign the same;
                            </p>
                        </div>
                        <div className="definition">
                            <h4>Pre-Existing Materials:</h4>
                            <p>
                                materials either owned by us or used by us under licence and which existed at the commencement of our
                                delivery of the Services or were created otherwise than exclusively for the purpose of the creation of the
                                Software and/or the delivery of the Project;
                            </p>
                        </div>
                        <div className="definition">
                            <h4>Project:</h4>
                            <p>the objective for which the Services are to be delivered, as described in the Contract</p>
                        </div>
                        <div className="definition">
                            <h4>Proposal:</h4>
                            <p>
                                means an offer from us to you for consideration or acceptance. A proposal offered to you for acceptance may
                                be withdrawn at any time before it is accepted. A bid can be withdrawn at any time before acceptance. If any
                                party wants to withdraw the proposal or bid, they need to provide written notice of the withdrawal to the
                                other party. When the proposal is accepted, the terms of the Contract should include the terms agreed in the
                                Proposal.
                            </p>
                        </div>
                        <div className="definition">
                            <h4>Services:</h4>
                            <p>the services specified in the Contract that we will deliver, subject to these Terms;</p>
                        </div>
                        <div className="definition">
                            <h4>Software:</h4>
                            <p>
                                the product of the Services, which will usually be delivered to you in a suitable electronic format of our
                                choosing;
                            </p>
                        </div>
                        <div className="definition">
                            <h4>Specification:</h4>
                            <p>the specification for the Software as specified in the Contract;</p>
                        </div>
                        <div className="definition">
                            <h4>Terms:</h4>
                            <p>
                                means these Terms &amp; Conditions of Business, which includes any other documentation referred to in them,
                                such as Special Conditions, contract variations or our Privacy Policy;
                            </p>
                        </div>
                        <div className="definition">
                            <h4>Warranty Period:</h4>
                            <p>
                                the period specified in the Proposal (or if no such period is specified, a period of 28 days) commencing on
                                the date of Completion.
                            </p>
                        </div>

                        <p>
                            The headings in the Terms are for convenience only and will not affect their interpretation. Any reference to
                            any enactment (statutes, regulations, etc.) includes a reference to that enactment as amended from time to time
                            and to any subordinate legislation made thereunder.
                        </p>
                    </TextBlock>
                    <TextBlock title="2. How the Contract Works">
                        <ol>
                            <li>
                                When we use words in this document such as “we”, “us” or “our”, we are referring to Crea d.o.o.. We are a
                                company registered in Slovenia and our registered office is located at Brnčičeva 39, 1231 Ljubljana -
                                Črnuče, Slovenia. Our company registration number is 1662163000 and our VAT registration number is 83782796.
                            </li>
                            <li>
                                When we use words such as “you” or “your”, we are referring to you, our client. However, it may be that you
                                are actually an agency engaging us to deliver services on behalf of your client, in which case, for the most
                                part, we will be referring to your client.
                            </li>
                            <li>
                                When you are engaging us as an agent in legal terms (which is to say, you are hiring us on behalf of your
                                client), you confirm that you have the authority to bind your client into this contract with us. If you do
                                not have that authority, you will be liable for any failure by your client to honour what would have been
                                its commitments under these Terms.
                            </li>
                            <li>
                                If you are sub-contracting to us, our contract is with you and you will be entirely responsible for any
                                failure to comply with these Terms. We do not offer Services “on spec” and we will not deliver services on
                                the basis that the project has “yet to be signed off” or that “finance has still to be agreed” or anything
                                similar. If you sub-contract to us, you confirm that you are ready for us to proceed as outlined in the
                                Contract and that you will comply with these Terms. If you are not ready to enter into a legally enforceable
                                relationship, please don’t contract with us until you are. This is not a matter of trust or goodwill. It is
                                simply about making sure we all know where we stand.
                            </li>
                            <li>
                                By signing the Contract, you confirm that you read and agreed with the Terms and Conditions of the
                                Contractor.
                                <br />
                                Our Terms and Conditions are sent to you with the Contract Agreement.
                                <br />
                                Alternatively, you can access the terms and conditions on our website:{' '}
                                <a href="https://www.equaleyes.com/terms" rel={"nofollow"}>https://www.equaleyes.com/terms</a>.
                            </li>
                            <li>
                                After you agree with the Proposal, we send you the Contract and Terms. Please bear in mind that it is your
                                responsibility to read the Terms and to check that the Contract is correct. Don’t assume that it is. You
                                accept the Contract when you send us the signed contract back. Instead of such a response, you may also
                                accept the contract by paying a deposit or the first installment of fees or by instructing us to commence
                                the delivery of the Services or by some other act that indicates your intention to proceed (such as sending
                                us materials for use in the Project).
                            </li>
                            <li>
                                In the Proposal, we will prepare a "Project cost Estimate". Such a document will be provided in good faith,
                                which means that deviations may occur during the Project. This document will be also added to the Contract,
                                but it will carry no legal weight.
                            </li>
                            <li>
                                After both parties (you and we) sign the Contract in accordance with clause 2.6. above, there will be an
                                enforceable contract between us. That Contract is made up of t Contract, Terms, and all attached appendix,
                                for example, "Project cost estimate".
                            </li>
                        </ol>
                    </TextBlock>
                    <TextBlock title="3. Changes">
                        <ol>
                            <li>
                                <div className="definition">
                                    <h4>Changes in this Terms</h4>
                                    <p>
                                        Sometimes it is necessary to make changes to these Terms. Where we are doing that at the outset, we
                                        will notify you promptly, you will have time to read the changes and accept them.
                                    </p>
                                    <p>Changes will be accordingly published on our website.</p>
                                </div>
                            </li>
                            <li>
                                <div className="definition">
                                    <h4>Changes in the Contract</h4>
                                    <p>
                                        It is often necessary to make changes to a project as it proceeds. Generally, such changes will be
                                        details that do not affect the Contract. For example, you may wish to adopt a revised logo or
                                        colour-palette. However, where it becomes necessary to change any of the details contained in the
                                        Contract (say, should it become obvious that the design you have approved will not fit with the
                                        functionality you want from the Software and either one, the other or both must be amended) these
                                        changes will only take effect for the purposes of the contract where the following procedure is
                                        followed:
                                        <ol>
                                            <li>
                                                Should you wish to make changes to the scope of the Services to be delivered, you will
                                                confirm such changes in writing (even when we have discussed these changes with you orally).
                                            </li>
                                            <li>
                                                On receiving your written request to make changes, we will confirm in writing the effect
                                                that making the changes in question will have on the Contract overall. This may mean a
                                                change to the pricing, the estimated delivery date or there may be other changes. It may
                                                also be necessary in some cases to ask you to settle further expenses before they are
                                                incurred.
                                            </li>
                                            <li>
                                                Should you wish to proceed with the changes, you must then respond as directed, confirming
                                                that you accept the changes proposed. At that point (and only at that point) the Contract
                                                will be varied to take account of the changes made.
                                            </li>
                                        </ol>
                                    </p>
                                </div>
                            </li>
                            <li>
                                Occasionally, it may be necessary to make technical changes to these Terms that do not involve the type of
                                changes to scope, delivery or cost of the project envisaged by clause 3.2. Where this is necessary, no such
                                variation of these Terms shall be valid unless it is confirmed in writing and signed by at least one of our
                                Directors.
                            </li>
                            <li>
                                Leaving clauses 3.2 and 3.3 aside for the moment, it will often be necessary for us to make decisions during
                                a Project that give rise to minor changes to the Services but which do not affect the overall scope of the
                                Services to be delivered, the Software or the outcome of the Project as a whole. In such a situation, it may
                                be impractical or even impossible to consult with you within a reasonable timeframe, therefore you agree
                                that we may make minor changes like this without consulting you as long as they do not adversely affect the
                                outcome of the Project in a material way.
                            </li>
                            <li>
                                The Contract, as defined in and by these Terms, constitutes the entire agreement between you and us and it
                                supersedes any previous agreements made or promises given either by you or by us with regarding the Project.
                                If a statement is not written into the Contract, it doesn’t form part of the Contract and, save for
                                instances of fraud or fraudulent misrepresentation, it won’t be enforceable. If, having read the
                                Proposal/Quote and the final version of the Contract before signing, you believe there to be any important
                                detail missing, please tell us so that we can amend the Proposal/Quote or the Contract to take this into
                                account. It is essential that everyone is on the same page as far as the Project is concerned and it is
                                better for the Contract to be a bit too detailed than to leave things out that could give rise to a
                                disagreement over whether something was or wasn’t included within the Contract. There is no room for
                                implying that certain things were to be included here. It’s either in or it isn’t and if it’s in, it will be
                                cited somewhere in the documentation that makes up the Contract.
                            </li>
                            <li>
                                Once our Contract with you has been formed, you may not cancel the Project or purport to terminate the
                                Contract without our express consent. The provision of our consent will be conditional on a financial
                                settlement between you and us that reflects lost production time (including an element of profit thereon),
                                wasted labour and materials and expenses incurred.
                            </li>
                            <li>
                                If you breach any of these Terms, then in cases where that breach can be rectified, you fail to correct that
                                breach within 30 days of written notice from us specifying the breach and requiring its rectification, we
                                may terminate the Contract on written notice with immediate effect. Where the breach is not capable of
                                rectification, we may terminate the Contract with immediate effect by giving you written notice. Should it
                                become necessary for us to terminate the Contract then our termination will not affect any Fees or other
                                sums chargeable to and payable by you in respect of Services provided to you up to and including the
                                effective date of that termination.
                            </li>
                            <li>
                                By way of an alternative to our rights under clause 3.7, at any time following written notice from us
                                specifying the breach and requiring its rectification, we may suspend our delivery of the Services
                                (including withholding the delivery of any Software that is ready for Completion) until you have corrected
                                the breach in question. If we choose to suspend the delivery of Services pending rectification of a breach
                                cited in our notice to you, we won’t be liable for any harm that you suffer as a result of the ensuing delay
                                in the delivery of the Services as long as we resume delivery of Services within a reasonable time following
                                the rectification of the breach in question.
                            </li>
                            <li>
                                We don’t take our rights to terminate the Contract or suspend delivery of Services (whether under clauses
                                3.7 and 3.8 or provided for elsewhere in the Terms) lightly. We will only do this where we feel that to
                                safeguard our business, we have no other option. The best way to avoid this happening is to maintain a
                                dialogue when things are difficult. Consequently, if you become aware of a breach by you of your obligations
                                under these Terms (or you become away that such a breach is likely to occur), you will inform us of this in
                                writing as soon as possible. We will then work with you in good faith to try to minimise the impact that any
                                such breach has on the Project.
                            </li>
                        </ol>
                    </TextBlock>
                    <TextBlock title="4. Charges & Payment">
                        <ol>
                            <li>
                                Unless we have stated explicitly to the contrary, the Fees are exclusive of VAT and other applicable taxes,
                                which you will pay in addition.
                                <br />
                                We will usually list in the Proposal and the Contract the expenses that we are likely to incur in delivering
                                the Services (for practical purposes, bear in mind that such expenses may be estimates only, which are given
                                in good faith but are subject to change), but whether we do or do not, any such expenses will be paid by you
                                in addition to the Fees.
                            </li>
                            <li>
                                If we incur further expense or cost (including administrative or legal costs) as a result of:
                                <ol>
                                    <li>damage caused by you or other third parties to the Software; or</li>
                                    <li>your failure to provide us with adequate or any instructions; or</li>
                                    <li>
                                        your provision of instructions that are inaccurate, erroneous or unfinished, we may charge these
                                        costs and expenses in addition to the Fees.
                                    </li>
                                </ol>
                                We will notify you before taking actions.
                            </li>
                            <li>
                                You will pay all sums owed to us (including any VAT or other applicable tax) within 14 days of the date of
                                our invoice accounting for those sums.
                            </li>
                            <li>
                                If you fail to make payment in accordance with clause 4.3 above, we may charge you interest on any unpaid
                                amount (both before and after judgment) at a daily rate equivalent to 8% per annum above the Official Bank
                                Rate from time to time of the Bank of England from the date upon which any such sum became due for payment
                                to the date upon which payment is ultimately received. We may invoice for any such accrued interest at any
                                time and if such further invoice is not settled in accordance with clause 4.3, we may charge interest on
                                that invoice also in accordance with this clause 4.4.
                            </li>
                            <li>
                                If it becomes necessary for us to issue legal proceedings in respect of any breach by you of these Terms or
                                of the Contract as a whole, you will reimburse us on an indemnity basis for all costs and expenses that we
                                incur as a result of having to take that action.
                            </li>
                            <li>
                                Should you fail to make payment as required by these Terms, we may withhold or suspend delivery of the
                                Services until such payment is made and we will not be liable for any loss or damage you suffer as a result
                                of the delay in delivery. In the event that you fail to settle any sums due to us in accordance with these
                                Terms, we may terminate the licence of the Software granted to you at clause 7.1 on notice in writing with
                                immediate effect.
                            </li>
                        </ol>
                    </TextBlock>
                    <TextBlock title="5. Service Delivery">
                        <ol>
                            <li>
                                We will exercise reasonable skill and care in providing the Services and we promise that all individuals
                                tasked by us in the delivery of the Services will be suitably trained and skilled for the purposes of the
                                Project. We promise that during the Warranty Period, the Software will meet or exceed the Specification and
                                if it does not, we will, as soon as we reasonably can, fix any issues that prevent it from doing so. We
                                don’t promise that the use of the Software will be uninterrupted or error free because it’s simply not
                                possible to do that. If this concerns you, we can offer support contracts to keep the Software up-to-date
                                and to fix any problems that might arise from time to time.
                            </li>
                            <li>
                                Timescales and delivery dates stated in the Proposal are estimates only. Given the nature of the Services
                                that we deliver, it is not possible to guarantee that any dates for delivery will be met but they are given
                                in good faith. You are not entitled to terminate the Contract because the Project won’t be delivered in
                                accordance with any timescales or delivery dates (whether as specified in the Proposal or subsequently
                                indicated).
                            </li>
                            <li>
                                You acknowledge that our ability to provide the Services on time and on budget depends upon your full and
                                timely co-operation as well as the accuracy and completeness of any Input Material required. You agree to
                                provide us with all such Input Material that we might reasonably require for the Project. We may have listed
                                in the Proposal Input Material that, at the outset of the Project, we think we will need from you but if so,
                                this won’t be a comprehensive list. It may be that there is more Input Material required, especially if
                                changes are made during the delivery of the Services. You also agree to be responsible for checking each
                                module and iteration in order to ensure and confirm that the Software meets the Specification.
                            </li>
                            <li>
                                Prior to effecting Completion in accordance with clause 5.5, we will test the Software on a range of devices
                                to ensure that in our discretion it is operable and capable of meeting the Specification. However, it is not
                                possible to test the Software comprehensively on all combinations of devices and platforms, especially where
                                Android developments are concerned. For iOS developments, we will test the Software on the latest and last
                                iOS iterations and the current and last Apple mobile devices. For Android developments, we will choose a
                                range of the most popular devices that we believe in good faith the users in your target market will most
                                likely be using, running the iterations of Android that we believe they will be running. For web
                                applications, we will test the Software on the current versions of the most popular browsers on the most
                                popular desktop and mobile platforms. In addition, we will test the Software on any combination of device
                                and platform specified by you and recorded in the Proposal. Where you do not inform of us of any such
                                requirement until after this Contract has been formed, we will treat this as a change request for the
                                purposes of clause 3.2 and will proceed accordingly.
                            </li>
                            <li>
                                The Project will be completed on the first of the following events to occur:
                                <ol>
                                    <li>
                                        You are satisfied that the Software meets the Specification and you inform us of this in writing; or
                                    </li>
                                    <li>
                                        You fail to indicate your satisfaction or alternatively within 14 days of us letting you know that
                                        the Software is available to you for testing you fail to indicate any issues that suggest in your
                                        view that the Software falls short of the Specification; or
                                    </li>
                                    <li>
                                        You upload the Software to a Distributor or you instruct us (or a third party) to do so on your
                                        behalf; or
                                    </li>
                                    <li>
                                        You make the Software available to the public (or to its intended audience) via the web or you
                                        instruct us (or a third party) to do so on your behalf.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                The successful exploitation of any software project depends on the onward support, maintenance and onward
                                development of the software delivered. In particular, the warranty we give about the performance of the
                                Software during the Warranty Period at clause 5.1 should not be seen as a substitute for a support contract
                                – there are many minor issues that might arise that don’t render the Software’s performance as falling short
                                of the Specification but which you may want us to address nonetheless. Platforms and operating systems are
                                continually evolving and new devices with new capabilities are being launched all the time. Any support
                                services that we are to deliver post-Completion will be as detailed in the Proposal only and will be subject
                                to the payment by you of the Fees in accordance with clause 4.3 and such other terms and conditions as might
                                be referenced in the Proposal for that purpose. Any additional services that you may require beyond
                                Completion will be the subject of additional fees at our standard rates as applicable from time to time for
                                the services required.
                            </li>
                        </ol>
                    </TextBlock>
                    <TextBlock title="6. Input Material and Intellectual Property">
                        <ol>
                            <li>
                                You confirm that you are the sole beneficial owner of any and all Intelectual Property (“IP”) in the Input
                                Material or that you are entitled to make use of the Input Material and to grant a sub-licence of those
                                rights to us (which licence you do hereby grant) for the purpose of the provision of the Services and the
                                delivery of the Software.
                            </li>
                            <li>
                                We acknowledge that you (or your licensors) are the owners of all IP in the Input Material and shall remain
                                so throughout the Project. We will only use the Input Material for the purposes of delivering the Services
                                and we shall return any hard copy IP to you at the end of the Project.
                            </li>
                            <li>
                                You warrant that the Input Material does not contain inappropriate material such as material that infringes
                                applicable laws, regulations or the rights of third parties.
                            </li>
                            <li>
                                You will indemnify us in full against all liabilities, costs and expenses that we might incur as a result of
                                using Input Material or preparing the Software that arise from the infringement of IP belonging to third
                                parties.
                            </li>
                            <li>
                                You will give us access to any data that is to be migrated as part of the Services in sufficient time to
                                permit us to meet our obligations in the Contract and under these Terms. You are entirely responsible for
                                the accuracy and completeness of that data. As long as we exercise reasonable care and skill in the
                                migration of any such data, we won’t be liable for any loss or damage that happens to the data during its
                                migration. Unless the Contract expressly states to the contrary, it is your responsibility to ensure that
                                all data (and any other important information or records) are backed up before we use it for the purposes of
                                the delivery of the Services.
                            </li>
                            <li>
                                You warrant that you hold all applicable licences and permissions in respect of any data to be migrated
                                and/or used in combination with the Software and that the data concerned is not corrupt or contaminated by
                                viruses or other malware. You will indemnify us in respect of any loss or damage caused as a result of your
                                breach of the warranty given in this clause 6.6.
                            </li>
                            <li>
                                We will not be liable to you for any loss or damage to information or data stored on your systems or on
                                systems that you control during an installation or otherwise. You are the best judge of the value of your
                                data and you are responsible for ensuring that you have adequate back-up arrangements in place for all such
                                information, data and other files at all times.
                            </li>
                        </ol>
                    </TextBlock>
                    <TextBlock title="7. Software">
                        <ol>
                            <li>
                                Prior to Completion, you may use any Software only for internal review and deliberation for the purposes of
                                Project. The transmission or publication of the Software without our written consent is strictly forbidden.
                                Any publication of the Software prior to Completion will constitute an infringement in respect of which we
                                will be entitled to take action. You acknowledge that any damage we suffer is likely to be difficult to
                                assess in financial terms and so we will be entitled to seek an injunction to restrain further
                                infringements.
                            </li>
                            <li>
                                Strictly conditional upon:
                                <ol className="roman-list" type="i">
                                    <li>
                                        the Completion of the Project in accordance with clause 5.5 of these Terms or the earlier
                                        termination of the Contract by mutual consent; and
                                    </li>
                                    <li>
                                        the settlement in full of Fees due to us (other than Fees due in respect of support, maintenance and
                                        onward development of the Software post-Completion)
                                    </li>
                                </ol>
                                and subject also to the licence referenced at clause 7.3 below, we hereby assign to you by way of future
                                assignment all title to the copyright in the Software, such assignment to take effect upon receipt of the
                                payment the last outstanding instalment of such Fees relating to the delivery of Services through which the
                                Software will be created or the Completion of the Project in accordance with clause 5.5 or earlier
                                termination by mutual consent, whichever is the latter.
                            </li>
                            <li>
                                Subject to any Embargo Date that we have agreed with you, following Completion we may exhibit the Software
                                in our portfolio in order to market our services to third parties. The assignment referenced above is
                                subject to the grant by you of a non-exclusive licence to us to use such Software in our portfolio and for
                                marketing purposes.
                            </li>
                            <li>
                                We will execute all documents, give all assistance and do all acts and things at your expense as may be
                                necessary or reasonably desirable to vest in you the copyright in the Software delivered. You will record
                                and expressly recite in any such documentation the existence of our right to reuse the Software in question
                                in our portfolio and for marketing purposes.
                            </li>
                            <li>
                                Although we will assign to you the copyright in the Software, all IP rights in the Pre-Existing Materials
                                are and shall remain our property (or the property of our licensors). You may not make use of, copy or
                                disclose to any third party any scripts, underlying principles or ideas in any Services or Software, whether
                                or not such script, principles or ideas were discovered through your lawful use of the Software.
                            </li>
                            <li>
                                The Software may contain technical measures that we may use to ensure that the Software is only used for the
                                purposes stated in the Contract and which may remain active only up until the point at which title to the
                                copyright in the Software transfers to you by virtue of clause 7.2.
                            </li>
                            <li>
                                Save for any infringement arising from your breach of your obligations in respect of the Input Material, we
                                hereby warrant that as far as we are aware the Software (and the use thereof) will not infringe the IP
                                rights of any third party. Should we breach this warranty, we will indemnify you against any loss, damage,
                                cost, expense or other claims arising from any such infringement.
                            </li>
                            <li>
                                It can often be more efficient for us to use materials created and/or owned by third parties as part of the
                                creation of the Software. Where this is the case, we will include reference to these third party materials
                                in the Specification or more generally in the Proposal. Your use of these materials following Completion
                                will be subject to a licence from the owners or controllers of the rights in those materials, which we shall
                                provide to you at Completion if not before. We will review the terms of the licences applicable for those
                                materials but you accept that ultimately it is your responsibility to ensure that the rights that the owners
                                or controllers of those materials grant are sufficient or your purposes and if necessary, you will seek
                                advice on this from a lawyer specialising in the subject. You accept that if you do not accept the terms of
                                a licence that we reasonably feel to be sufficient for the purposes of the Project and as a result of which
                                you require us to source other such materials (or even create them ourselves), any additional time or
                                expense that we incur will be chargeable in addition to the Fees at our standard studio rates.
                            </li>
                            <li>
                                The Input Material and any other third party-sourced materials aside, we warrant that we will have title to
                                and property in the Software, which property will be free and unencumbered and/or that we have the right,
                                power and authority to licence and ultimate to assign the copyright in the Software to you in accordance
                                with these Terms.
                            </li>
                        </ol>
                    </TextBlock>
                    <TextBlock title="8. Confidentiality">
                        <ol>
                            <li>
                                Except as otherwise provided in these Terms and subject to clauses 8.3 and 8.4, we will treat all Input
                                Material as strictly confidential and we won’t disclose it to any third party other than third party
                                sub-contractors who are bound by an obligation of confidentiality to us and to whom it is necessary to
                                provide the Input Material in order for us to deliver the Services.
                            </li>
                            <li>
                                Prior to Completion, you will treat the Software, our Contract and all prices quoted as strictly
                                confidential and you shall not display, use or disclose the same to any third party for any reason
                                whatsoever. You may not use our name or the names of any of our employees or contractors involved in the
                                delivery of the Services in publicity relating to the Project unless we agree in writing in advance of the
                                publication of such publicity.
                            </li>
                            <li>
                                Clauses 8.1 and 8.2 will not apply to any information or materials:
                                <ol>
                                    <li>
                                        already known to the receiving party or in its possession before the disclosure free from any
                                        obligation to keep the same confidential;
                                    </li>
                                    <li>
                                        that is or becomes public knowledge through no fault of the receiving party;
                                        <br />
                                        received from a third party without similar obligations of confidence and without breach of the
                                        Terms;
                                    </li>
                                    <li>that can be proven to have been developed independently;</li>
                                    <li>
                                        that the disclosing party discloses to a third party without similar restrictions on that third
                                        party’s rights of disclosure; or
                                    </li>
                                    <li>approved for release by written permission of the disclosing party.</li>
                                </ol>
                            </li>
                            <li>
                                Should it become necessary for us to terminate the Contract under clause 3.7 or clause 4.6, our obligations
                                of confidentiality to you will come to an end and you grant us permission to re-use the Input Material so
                                that we may re-purpose the Software in order to mitigate any loss or damage we have suffered and for which
                                we have not been compensated.
                            </li>
                            <li>We agree that we shall make no reference to the Project or the Contract until the Embargo Date.</li>
                        </ol>
                    </TextBlock>
                    <TextBlock title="9. Liability">
                        <ol>
                            <li>
                                We will not be liable to you for any loss, damage, cost, expense or other claims for compensation arising
                                from any Input Material or any instructions that you supply that are incomplete, incorrect, inaccurate,
                                illegible, out of sequence or in the wrong form or format or which arises from the late or non-arrival of
                                such instructions or due to any other fault of yours or any delay in transit that is not caused by us.
                            </li>
                            <li>
                                Save as provided for at clause 9.6 below, we will not be liable to you by reason of:
                                <ol>
                                    <li>
                                        any promise we’ve made in advance of forming the Contract with you (in legalese, a
                                        misrepresentation);
                                    </li>
                                    <li>breach of implied warranty, condition or other term;</li>
                                    <li>
                                        breach of any duty at common law or any duty imposed on us by virtue of any statute or regulation;
                                        or
                                    </li>
                                    <li>
                                        breach of these Terms for any loss of profit, reputation, opportunity, goodwill, business or
                                        anticipated savings or any indirect, special or consequential loss, damages, costs, expenses or
                                        other claims (whether caused by our negligence or that of our employees, contractors or agents or
                                        otherwise) that arise out of or in connection with the delivery or late delivery of the Services or
                                        the nature or quality of the Software, or the failure to provide the Software or Services to you or
                                        any third party.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Save as provided for at clause 9.6, our entire liability under or in connection with the Terms shall not
                                exceed the Fees charged or chargeable in the 12 months prior to the occurrence of the event giving rise to
                                the liability in question.
                            </li>
                            <li>
                                Unless otherwise stated in the Contract, we don’t promise that the Software will be suitable for use by all
                                or any persons with disabilities and we will not be deemed to be in breach of our obligations because of any
                                such non-suitability. If you want the Software to be suitable for use by persons with disabilities, you must
                                tell us this before the Project begins and to specify your precise requirements in full and in writing in
                                those respects so that they can be recorded in the Specification or otherwise in the Contract. Those
                                requirements may effect our ability to deliver the Services in accordance with the Proposal should we
                                receive them after the Contract has been agreed, in which case they will constitute a change request for the
                                purposes of clause 3.2. Regardless of the nature of the Services that we are to deliver, you remain
                                responsible for ensuring that the Software complies with the Disability Discrimination Act 1995 and you will
                                indemnify us against any loss, damages, costs, expenses or other claims arising from any breach of that Act
                                or any regulations made under it or relating to it.
                            </li>
                            <li>
                                Nothing in these Terms shall operate so as to exclude or limit our liability for fraud or fraudulent
                                misrepresentation, for death or personal injury arising from our negligence or for any other liability for
                                which it would be unlawful for us to exclude or limit.
                            </li>
                            <li>
                                All indemnities that we give under these Terms are provided on the basis that:
                                <ol>
                                    <li>
                                        you give notice to us of the circumstances giving rise to any such indemnity as soon as you become
                                        aware of the same;
                                    </li>
                                    <li>
                                        you provide us with sole conduct of the defence to any claim or action in respect of any
                                        infringement or other issue giving rise to the indemnity and you do not at any time admit liability
                                        or otherwise attempt to settle or compromise the claim or action except upon our express
                                        instructions;
                                    </li>
                                    <li>
                                        you act in accordance with our reasonable instructions including providing us with whatever
                                        assistance we might reasonably require in respect of the conduct of the defence of the claim
                                        including the preparation of any court documentation or the attendance at any court hearings; and
                                    </li>
                                    <li>
                                        the claim or action giving rise to the damages (including legal costs) award to or agreed with the
                                        third party does not arise, whether entirely or in part, from your breach of any part of clause 6
                                        above.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </TextBlock>
                    <TextBlock title="10. General">
                        <ol>
                            <li>
                                We won’t be liable for any failure to deliver or delay in delivering the Services caused by reasons that are
                                beyond our reasonable control.
                            </li>
                            <li>
                                Even though we may not insist on your compliance with an obligation on you under these Terms at any given
                                time, we may still require you to comply with that obligation in future or to correct your non-compliance.
                            </li>
                            <li>
                                We will process all “personal data”, as defined by the Data Protection Act 1998 (“the DPA”) and General Data
                                Protection Regulation 2016 (“the GDPR”) that you provide to us, in compliance with the DPA and in accordance
                                with our Privacy Policy.
                            </li>
                            <li>
                                We will not be liable for any breach of the DPA by any sub-contractor to whom we may lawfully pass personal
                                data relating to the Project. You consent to the holding, processing and accessing of personal data provided
                                to us for the purpose of the Project including the transferring of such personal data to a sub-contractor or
                                an agent for the purposes of debt collection. We will notify you of all sub-contractors to whom we have
                                passed such personal data in delivering the Services so that, where appropriate, you may enter into data
                                processing agreements with them, should you feel that to be necessary.
                            </li>
                            <li>
                                Nothing in the Terms shall confer on any third party any benefit whatsoever or the right to enforce any term
                                of any contract to which these Terms relate and the Contract (Rights of Third Parties) Act 1999 shall not
                                apply.
                            </li>
                            <li>
                                Leaving aside to any other rights we might have, either under these Terms or otherwise, we may terminate the
                                Contract or, notwithstanding any previous agreement or arrangement to the contrary, suspend any further
                                delivery without liability to you and where the Software has been delivered but not paid for the price shall
                                become immediately due and payable if:
                                <ol>
                                    <li>you make any voluntary arrangement with your creditors, or</li>
                                    <li>(being an individual or partnership) become bankrupt, or</li>
                                    <li>
                                        (being a company) become subject to an administration order (whether out of court or otherwise), or
                                        go into liquidation (otherwise than for the purposes of amalgamation or reconstruction) or an
                                        encumbrancer takes possession of or a receiver or administrator is appointed over any of your
                                        property or assets, or
                                    </li>
                                    <li>you cease to trade or threaten to cease trading.</li>
                                </ol>
                            </li>
                            <li>
                                If a court or other authority decides that any provision or part-provision of the Contract is invalid,
                                illegal and/or unenforceable then the provision or part-provision in question will be deemed to have been
                                deleted and the validity of the rest of the Contract will be unaffected. If it is possible to make the
                                provision or part-provision in question valid, legal and/or enforceable if some part of it were deleted, the
                                provision or part-provision shall apply with the minimum modification necessary to make it legal, valid and
                                enforceable.
                            </li>
                            <li>
                                The Contract shall in all respects be determined and governed by Slovenian law. Both we and you submit to
                                the exclusive jurisdiction of the Slovenian courts.
                            </li>
                        </ol>
                    </TextBlock>
                </Container>
            </Wrapper>
        </Layout>
    );
};
